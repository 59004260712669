import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-is-the-accessibility-tree"
    }}>{`What is the Accessibility Tree?`}</h2>
    <p>{`The accessibility tree is a hierarchical structure utilized by the accessibility API on a user's
device. This structure exposes vital accessibility information users of assistive technologies
require to perceive, operate, and understand user interfaces.`}</p>
    <p>{`The information provided by the accessibility tree includes essential information such as any names,
roles, and states for form elements, links, and content in the user interface.`}</p>
    <h2 {...{
      "id": "why-is-the-accessibility-tree-important-for-user-experience"
    }}>{`Why is the Accessibility Tree Important for User Experience?`}</h2>
    <p>{`Assistive technologies will only operate reliably when the correct accessibility information is
provided to the accessibility tree.`}</p>
    <p>{`If the correct accessibility information isn't provided, an assistive technology user will find
using a digital product difficult or impossible.`}</p>
    <p>{`People with disabilities `}<strong parentName="p">{`depend`}</strong>{` upon various assistive technologies being sent the correct
information to allow them to use our digital products.`}</p>
    <h2 {...{
      "id": "the-concept-of-technical-accessibility"
    }}>{`The Concept of "Technical" Accessibility`}</h2>
    <p>{`Ensuring that User Interfaces are correctly presented to the accessibility API on our users' devices
is commonly referred to as 'technical accessibility'.`}</p>
    <p>{`Technical accessibility is essential.`}</p>
    <p>{`It is one of the most fundamental parts of making user interfaces work for assistive technology
users.`}</p>
    <p>{`Technical accessibility is a crucial ingredient of broader usability for assistive technology users.`}</p>
    <p>{`Ensuring our digital products can be operated effectively with a wide range of assistive
technologies is foundational for enabling accessibility.`}</p>
    <h2 {...{
      "id": "what-are-names-roles-and-states"
    }}>{`What Are Names, Roles, and States?`}</h2>
    <p>{`To guarantee a user interface is technically accessible, we need to understand what information
needs to be sent to the accessibility tree.`}</p>
    <p>{`Elements in the user interface will generally have some or all of the following properties needed to
be sent to the accessibility tree.`}</p>
    <h3 {...{
      "id": "name"
    }}>{`Name`}</h3>
    <p>{`An assistive technology uses the 'name' provided by the operating system or accessibility API as a
label for a user interface element.`}</p>
    <p>{`Example: A `}<inlineCode parentName="p">{`label`}</inlineCode>{` for a button can be provided by the visible text inside it. This is then
announced by assistive technology like a screen reader.`}</p>
    <p>{`There are several ways to provide a 'Name' for a user interface element. In the example below,
'Send' is the name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button type="button">Send</button>
`}</code></pre>
    <h3 {...{
      "id": "role"
    }}>{`Role`}</h3>
    <p>{`An assistive technology uses the 'role' provided by the operating system or accessibility API to
determine what a user interface element is and how to interact with it.`}</p>
    <p>{`Example: A user interface element needs a role to communicate if it is a radio button. An assistive
technology then knows what interaction pattern to follow or announce if someone listens to the user
interface.`}</p>
    <p>{`In the example below, adding `}<inlineCode parentName="p">{`type="radio"`}</inlineCode>{` to the `}<inlineCode parentName="p">{`<input>`}</inlineCode>{` element assigns it a role of radio and
conveys this to the accessibility API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<label for="cats">Cats</label>

<input id="cats" type="radio" />
`}</code></pre>
    <h3 {...{
      "id": "state"
    }}>{`State`}</h3>
    <p>{`An assistive technology uses the 'state' provided by the operating system or accessibility API to
determine what state a user interface element currently has.`}</p>
    <p>{`Example: A checkbox input has a state to communicate if it is 'checked' or 'unchecked'. An assistive
technology then knows what interaction pattern to follow or announce if someone listens to the user
interface.`}</p>
    <p>{`In the example below, adding the `}<inlineCode parentName="p">{`'checked'`}</inlineCode>{` property to the `}<inlineCode parentName="p">{`<input type="checkbox">`}</inlineCode>{` conveys the
state to the accessibility tree.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<label for="dogs">Dogs</label>

<input id="dogs" type="checkbox" checked />
`}</code></pre>
    <h2 {...{
      "id": "html-and-semantics"
    }}>{`HTML and Semantics`}</h2>
    <p>{`Using correctly structured, validated, and semantic HTML in a user interface is integral in ensuring
the correct information is sent to the accessibility tree, which helps assistive technology users
perceive and understand the user interface.`}</p>
    <p>{`The name, role, and state properties are usually included when using standard HTML elements. HTML
affords us the best chance for accessibility.`}</p>
    <h3 {...{
      "id": "wai-aria-and-faux-semantics"
    }}>{`WAI-ARIA and 'Faux' Semantics`}</h3>
    <p>{`Although not always advised, it may be necessary to not use standard HTML and create a custom
control in some instances.`}</p>
    <p>{`For example, a non-semantic `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<span>`}</inlineCode>{` may be used to build a control instead of a standard
HTML form element like a `}<inlineCode parentName="p">{`<button>`}</inlineCode>{`.`}</p>
    <p>{`When this happens, a faux role, state, and property must be explicitly added to that `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` or
`}<inlineCode parentName="p">{`<span>`}</inlineCode>{` (and modified, if they change) so that they are correctly conveyed to assistive technology.`}</p>
    <p>{`This can be accomplished by using WAI-ARIA, which provides artificial semantics to user interface
elements.`}</p>
    <p>{`Example - a span with a WAI-ARIA `}<inlineCode parentName="p">{`role="button"`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<span role="button" tabindex="0">Faux</span>
`}</code></pre>
    <p>{`WAI-ARIA should be used with caution and only as a last resort when using standard HTML elements is
impossible.`}</p>
    <p>{`If used without due attention and thought, it can make a user interface more inaccessible than
accessible.`}</p>
    <p>{`We also need to be wary of inconsistent WAI-ARIA support in various browsers and assistive
technology combinations.`}</p>
    <p>{`Using WAI-ARIA without testing support means we are potentially introducing new barriers when trying
to remove them.`}</p>
    <h2 {...{
      "id": "screen-reader-inconsistencies-and-accessibility-bugs"
    }}>{`Screen Reader Inconsistencies and Accessibility Bugs`}</h2>
    <p>{`It is normal and anticipated to have slightly varying announcements across different browsers and
screen readers.`}</p>
    <p>{`This inconsistency results from the different combinations of discrete accessibility platform APIs,
accessibility trees, and screen readers.`}</p>
    <p>{`To ensure a user interface will be consistently operable across different screen readers or
browsers, you must correctly expose the name, role, and state information in the interface to the
accessibility tree in each browser. After this, each brand of screen reader needs to correctly
consume that information from the accessibility tree.`}</p>
    <p>{`When testing functionality with several screen reader and browser combinations, hearing slightly
different terminology or order of announcements is expected, so accessibility user acceptance tests
need to be aware of this.`}</p>
    <p>{`It is more beneficial to focus on whether what you hear from the variety of screen readers is
generally consistent and the interaction is understandable. Any name, role, and state information
should be correctly announced.`}</p>
    <h3 {...{
      "id": "on-accessibility-bugs"
    }}>{`On Accessibility Bugs`}</h3>
    <p>{`In a classic sense, an accessibility bug is a mismatch between the accessibility tree and assistive
technology.`}</p>
    <p>{`Sometimes HTML code conveys the correct information to the accessibility tree, and we can view this
in the code inspector in a browser, but a screen reader is not announcing this as expected.`}</p>
    <p>{`This is a screen reader bug.`}</p>
    <p>{`Another issue could be the HTML code conveys the correct information to the accessibility tree, but
when you view the accessibility tree in your browser's code inspector, you can see that it has not
been presented.`}</p>
    <p>{`This is a browser bug.`}</p>
    <p>{`The best strategy for technical accessibility is to prototype HTML early in your process and test
with different browsers and assistive technology combinations to determine what markup offers the
most robust assistive technology support for the feature or functionality you are creating.`}</p>
    <h3 {...{
      "id": "how-to-view-the-accessibility-tree"
    }}>{`How to View the Accessibility Tree`}</h3>
    <p>{`You can view what is exposed to the accessibility tree in each web browser through the developer
tools.`}</p>
    <p>{`For example, in Google Chrome:`}</p>
    <ol>
      <li parentName="ol">{`Right-click anywhere on the page and select 'Inspect' to open Chrome Developer Tools.`}</li>
      <li parentName="ol">{`Under the 'Elements' tab, click the 'Accessibility' tab.`}</li>
      <li parentName="ol">{`The first item in the list is the Accessibility Tree.`}</li>
    </ol>
    <h3 {...{
      "id": "automated-accessibility-testing-tools"
    }}>{`Automated Accessibility Testing Tools`}</h3>
    <p>{`Automated accessibility testing tools are helpful. Amongst other things, they tell you if the
rendered code in the DOM has the correct role, states, and properties in accord with the HTML or
WAI-ARIA specifications.`}</p>
    <p>{`Note that this is not the same as telling you if the name, role, and state are correctly conveyed to
the accessibility tree. The browser DOM relates closely to what is sent to the accessibility tree,
but the browser DOM is not the same as the accessibility tree.`}</p>
    <p>{`This is why testing with assistive technology is essential.`}</p>
    <h2 {...{
      "id": "accessible-names-and-descriptions"
    }}>{`Accessible Names and Descriptions`}</h2>
    <p>{`Accessible names and accessible descriptions are vital when considering the screen reader listening
experience.`}</p>
    <h3 {...{
      "id": "accessible-name"
    }}>{`Accessible Name`}</h3>
    <p>{`The 'name' part of the name, role, and state is sometimes called the accessible name.`}</p>
    <p>{`The accessible name is a label that provides information about the purpose of a user interface
element.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`button`}</inlineCode>{`, for example, without an accessible name, will just be announced as a non-specific
'button' by a text to speech assistive technology like a screen reader.`}</p>
    <p>{`Without an accessible name, its purpose will be unknown to the screen reader user.`}</p>
    <p><strong parentName="p">{`Example`}</strong>{` - A button without any accessible name`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button type="button"></button>
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong>{` - (Elided) A button utilising an aria-label to provide an accessible name`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button type="button" aria-label="Send">
  <svg aria-hidden="true" focusable="false">...</svg>
</button>
`}</code></pre>
    <h3 {...{
      "id": "accessible-description"
    }}>{`Accessible Description`}</h3>
    <p>{`User Interface elements can also have what is called an accessible description.`}</p>
    <p>{`The accessible description is an optional 'hint' that will augment any name provided for that user
interface element. The hint is announced after a short delay by a screen reader.`}</p>
    <p>{`It is not required to provide a description as a name. A user interface element is adequate. Still,
it can help better communicate the purpose of any non-standard interaction.`}</p>
    <p>{`Hints can be turned off in the screen readers verbosity settings, so never place vital information
into an accessible description, as a screen reader user is not guaranteed to hear it.`}</p>
    <p>{`An accessible description is provided through the `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{` attribute.`}</p>
    <p><strong parentName="p">{`Example`}</strong>{` - using `}<inlineCode parentName="p">{`aria-describedby`}</inlineCode>{` to provide a hint`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button type="button" aria-describedby="hint">Theme</button> ...

<span id="hint">Change colors and text to suit your preferences</span>
`}</code></pre>
    <h3 {...{
      "id": "the-role-of-design"
    }}>{`The Role of Design`}</h3>
    <p>{`Technical accessibility is technical by definition, but the design has a crucial role to play to
help ensure key content has human-readable labels.`}</p>
    <p>{`Content design determines the choice of labels or descriptions by deciding on terminology, voice,
and tone across the product.`}</p>
    <p>{`Content design does not solely relate to the visible text displayed in the User Interface.
Therefore, it also must include text alternatives for all non-text elements of the User Interface.`}</p>
    <p>{`For more information on naming non-text content, please refer to
`}<a parentName="p" {...{
        "href": "/guidelines/accessibility/alt-text"
      }}>{`Alt text and Content Labels`}</a>{`.`}</p>
    <h3 {...{
      "id": "html-design-is-part-of-development"
    }}>{`HTML Design is Part of Development`}</h3>
    <p>{`Developers have a pivotal role in how assistive technology operates with our user interfaces.`}</p>
    <p>{`With some exceptions, most role and state information for user interface elements is included when
we use standard HTML.`}</p>
    <p>{`There is more than one way and some nuance in how to add an accessible name to a user interface
element.`}</p>
    <p>{`How this may be achieved will relate to the specific context and is a development choice. Accessible
naming will only be successful if the developer understands accessible name calculation.`}</p>
    <h4 {...{
      "id": "accessible-name-calculation"
    }}>{`Accessible Name Calculation`}</h4>
    <p>{`Browser vendors and assistive technology vendors agreed upon an algorithm for recursively computing
the name and description of an accessible object.`}</p>
    <p>{`The algorithm is necessary to ensure information about the User Interface is reliably and
consistently provided to users, regardless of the browser and assistive technology in use.`}</p>
    <p>{`The algorithm has an order of preference for calculating the accessible name for an element. In
addition, each user interface element has specific rules and pros and cons around how it can be
labelled.`}</p>
    <h5 {...{
      "id": "the-hierarchy-of-methods-for-providing-an-accessible-name"
    }}>{`The Hierarchy of Methods for Providing an Accessible Name`}</h5>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`aria-labelledby`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`aria-label`}</inlineCode></li>
      <li parentName="ol"><inlineCode parentName="li">{`<label>`}</inlineCode>{` with "`}<inlineCode parentName="li">{`for`}</inlineCode>{`" attribute (relates to form controls)`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`placeholder`}</inlineCode>{` (relates to input type text)`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`alt`}</inlineCode>{` (relates to images)`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`<caption>`}</inlineCode>{` (relates to tables)`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`<legend>`}</inlineCode>{` (relates to `}<inlineCode parentName="li">{`fieldset`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`innerText content (if applicable)`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`title`}</inlineCode>{` attribute`}</li>
      <li parentName="ol">{`value (input type button, submit, or reset)`}</li>
    </ol>
    <p>{`Like the accessible name, the accessible description also has a hierarchy for calculation, and each
approach has pros and cons.`}</p>
    <ol>
      <li parentName="ol"><inlineCode parentName="li">{`aria-describedby`}</inlineCode></li>
      <li parentName="ol">{`title`}</li>
      <li parentName="ol">{`placeholder`}</li>
    </ol>
    <h3 {...{
      "id": "summary"
    }}>{`Summary`}</h3>
    <p>{`Each decision we make in designing and developing a user interface has effects on all users,
especially those who depend on assistive technology.`}</p>
    <p>{`Understanding how the decisions we make affect how user interface elements are communicated to
assistive technologies helps us make better choices when building a Workday for everyone.`}</p>
    <h4 {...{
      "id": "further-information"
    }}>{`Further Information`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tpgi.com/the-browser-accessibility-tree/"
        }}>{`The Paciello Group - The Browser Accessibility Tree`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.smashingmagazine.com/2015/03/web-accessibility-with-accessibility-api/"
        }}>{`Leonie Watson - Accessibility APIs: A Key To Web Accessibility`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://a11ysupport.io/"
        }}>{`Accessibility Support - Will your code work with assistive technologies?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.levelaccess.com/how-the-w3c-text-alternative-computation-works/"
        }}>{`How the W3C Text Alternative Computation Works - Level Access`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/forms/labels/"
        }}>{`W3C - Labelling Controls`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA16"
        }}>{`Using aria-labelledby to provide a name for user interface controls`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tink.uk/the-difference-between-aria-label-and-aria-labelledby/"
        }}>{`The difference between aria-label and aria-labelledby`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tpgi.com/short-note-on-aria-label-aria-labelledby-and-aria-describedby/"
        }}>{`Short note on aria-label, aria-labelledby, and aria-describedby`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tpgi.com/using-the-html-title-attribute-updated/"
        }}>{`Using the HTML title attribute - The Paciello Group`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      